<template>
  <form-field :name="field.name">
    <black-pins
      v-model="field.value"
      v-bind="field.props"
    />
  </form-field>
</template>

<script>
import formField from '@/mixins/form-field'

export default {
  components: {
    BlackPins: () => import(/* webpackChunkName: "blackPins" */ '@/components/BlackPins'),
  },
  mixins: [formField],
}
</script>
